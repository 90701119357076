<template>
	<div class="c-app flex-row align-items-center">
		<CContainer>
			<CRow class="justify-content-center">
				<CCol
					md="8"
					lg="6"
					xl="5"
				>
					<CCardGroup>
						<CCard class="p-4">
							<CCardBody class="login-form">
								<CImg
									:src="logo"
									block
								/>
								<CForm>
									<h1>Login</h1>
									<p class="text-muted">Entra nel tuo account</p>
									<CInput
										placeholder="Email"
										autocomplete="email"
										@update:value="setEmail"
										:value="validatorObj.loginForm.email.$model"
										:isValid="requiredValidTouched(validatorObj.loginForm.email)"
									>
										<template #prepend-content>
											<CIcon name="cil-user" />
										</template>
									</CInput>
									<CInput
										placeholder="Password"
										type="password"
										autocomplete="current-password"
										@update:value="setPassword"
										:value="validatorObj.loginForm.password.$model"
										:isValid="requiredValidTouched(validatorObj.loginForm.password)"
									>
										<template #prepend-content>
											<CIcon name="cil-lock-locked" />
										</template>
									</CInput>
									<CRow class="actions-container">
										<CCol
											col="12"
											sm="12"
										>
											<CButton
												color="sdRed"
												class="px-4"
												@click="login"
												:disabled="validatorObj.loginForm.$invalid"
											>Login</CButton>
										</CCol>
									</CRow>
								</CForm>
								<CAlert
									class="mt-4"
									color="sdRed"
									v-if="hasError"
								>
									Email e/o password errati
								</CAlert>
							</CCardBody>
						</CCard>
					</CCardGroup>
				</CCol>
			</CRow>
		</CContainer>
	</div>
</template>

<script>

import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { requiredValidTouched, newGuid } from "../../utilities/utilities"

export default {
	name: "Login",
	setup() {
		return { validatorObj: useVuelidate() };
	},
	validations() {
		return {
			loginForm: {
				email: { email, required },
				password: { required }
			},
			isLoading: false
		}
	},
	data() {
		return {
			logo: require("@/assets/images/logo.png"),
			loginForm: {
				email: "",
				password: "",
			},
			hasError: false
		};
	},
	methods: {
		async login() {
			this.$store.commit('loadState', true);
			this.$store.dispatch('userLogin', { email: this.validatorObj.loginForm.email.$model, password: this.validatorObj.loginForm.password.$model }).then(x => {
				if (!x) {
					this.hasError = true;
				} else {
					this.$router.push('/');
				}
				this.$store.commit('loadState', false);
			})
		},
		removeError() {
			if (this.hasError) {
				this.hasError = false;
			}
		},
		setEmail(value) {
			this.removeError();
			this.validatorObj.loginForm.email.$model = value;
		},
		setPassword(value) {
			this.removeError();
			this.validatorObj.loginForm.password.$model = value;
		},
		requiredValidTouched,
		newGuid
	},
};
</script>

<style scoped lang="scss">
.login-form {
	img {
		width: 65%;
		margin: 0 auto 1rem;
	}
}

@media screen and (min-width: 576px) {
	.actions-container {
		& > div {
			&:last-of-type {
				text-align: right;
			}
		}
	}
}
</style>